'use strict'

// New subscription page mixin
// Manages the dropdowns for selecting subscription parameters

const $ = require('jquery')
const { encode } = require('../utils')

module.exports = ({ servicePriceList }) => $(() => {
  const $serviceSelect = $('#usecases')
  const $zipcodeSelect = $('#zipcode')
  const $durationSelect = $('#duration')
  const $submitButton = $('#get-new-number')

  function getCost (usecases, duration, formatted = false) {
    // parseInt will get rid of r/p suffix if present
    const cost = usecases.reduce((acc, usecase) => acc + Number(servicePriceList[usecase][parseInt(duration)] || 0), 0)
    return formatted ? cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : cost
  }

  function updateDurationCosts () {
    const usecases = $('#usecases').val()
    $durationSelect.find('option').each((i, el) => {
      if ($(el).val() === '') return
      $(el).attr('data-content', encode($(el).attr('data-label')) + (usecases.length ? `: <i class='fa fa-phone-square'></i> ${getCost(usecases, $(el).val(), true)}` : ''))
    })
    $durationSelect.selectpicker('refresh')
  }

  function updateServiceCosts () {
    const duration = $('#duration').val()
    $serviceSelect.find('option').each((i, el) => {
      if ($(el).val() === '') return
      $(el).attr('data-content', `<span class='usecase-price'><i class='fa fa-phone-square'></i> ${getCost([$(el).val()], duration, true)}</span> ${$(el).attr('data-label')}`)
    })
    $serviceSelect.selectpicker('refresh')
  }

  function updateAvailableServices () {
    const usecases = $('#usecases').val()
    const zipcode = $('#zipcode').val()
    const duration = $('#duration').val()
    const phoneNumber = $('#phoneNumber').val()

    $('.rental-price-container').show()
    $('#price').text(getCost(usecases, duration, true))
    // Admin field
    $('#cost').val(getCost(usecases, duration))
    updateDurationCosts()
    updateServiceCosts()

    $serviceSelect.find('option').prop('disabled', true)
    $zipcodeSelect.find('option').prop('disabled', true)
    $durationSelect.find('option').prop('disabled', true)
    $submitButton.prop('disabled', true)
    $('#private-numbers-warning').hide()

    $serviceSelect.selectpicker('refresh')
    $zipcodeSelect.selectpicker('refresh')
    $durationSelect.selectpicker('refresh')
    $.ajax({
      contentType: 'application/json',
      method: 'POST',
      url: '/services',
      data: JSON.stringify({
        usecases,
        zipcode,
        duration,
        phoneNumber
      }),
      dataType: 'json'
    }).then(
      (res) => {
        /* res = {
          zipcodes: ['00001', '00002', ...],
          usecases: ['FACEBOOK', 'TWITTER', ...]
        } */
        updateSelect($serviceSelect, res.usecases, 'usecases')
        updateSelect($zipcodeSelect, res.zipcodes, 'zip')
        const durations = Array.from($durationSelect.find('option')).map(option => option.value).filter(x => x)
        // Duration will be e.g. 7r or 30p, parseInt will get rid of the r or p
        updateSelect($durationSelect, durations.filter(d => !Number.isFinite(res.maxDuration) || parseInt(d) <= res.maxDuration), 'duration')
        $('#price').text(getCost(usecases, duration, true))
        // Admin field
        $('#cost').val(getCost(usecases, duration))
        updateDurationCosts()
        updateServiceCosts()
        const hasInvalidSetting =
          !usecases.every(usecase => res.usecases.includes(usecase)) ||
          (zipcode && !res.zipcodes.includes(zipcode)) ||
          (duration && res.maxDuration !== null && duration > res.maxDuration)
        $submitButton.prop('disabled', usecases.length < 1 || hasInvalidSetting)

        if (usecases.includes('_PRIVATE') && !res.usecases.length) $('#private-numbers-warning').show()
      },
      () => {
        $('#clientSideErrorMessage').text('A connection error has occurred, please reload the page.')
        $('#clientSideError').show()
      }
    )
  }

  function updateSelect ($select, availableEntries, mode, icons = {}) {
    const useIcons = icons.enabled || icons.disabled
    $select.find('option').data('subtext', 'currently unavailable')
    if (useIcons) $select.find('option').data('icon', icons.disabled ? 'fa fa-' + icons.disabled : '')

    // Never show currently selected items as disabled, to allow deselecting them
    // "currently unavailable" will still be shown
    $select.find('option:selected').prop('disabled', false)

    availableEntries.forEach(entry => {
      if (mode === 'usecases') {
        $select.find('.usecase-' + entry).prop('disabled', false).data('subtext', '')
        if (useIcons) $select.find('.usecase-' + entry).data('icon', icons.enabled ? 'fa fa-' + icons.enabled : '')
      }
      if (mode === 'zip') {
        $select.find('.zip-' + entry).prop('disabled', false).data('subtext', '')
        if (availableEntries.length > 0) $select.find('.zip-ANY').prop('disabled', false).data('subtext', '')
      }
      if (mode === 'duration') {
        $select.find('[value="' + entry + '"]').prop('disabled', false).data('subtext', '')
      }
    })
    $select.selectpicker('refresh')
  }

  updateAvailableServices()

  $serviceSelect.change(updateAvailableServices)
  $zipcodeSelect.change(updateAvailableServices)
  $durationSelect.change(updateAvailableServices)
  $('#phoneNumber').change(updateAvailableServices)

  $('.subscriptiontype').click(function () {
    const $this = $(this)

    const target = $this.data('target')

    if (target === 'private') {
      $serviceSelect.closest('.form-group').hide()
      $serviceSelect.find('.usecase-_PRIVATE').show().prop('disabled', false)
      $serviceSelect.val(['_PRIVATE'])
    } else {
      $serviceSelect.closest('.form-group').show()
      $serviceSelect.find('.usecase-_PRIVATE').hide().prop('disabled', true)
      $serviceSelect.val([])
    }

    $serviceSelect.selectpicker('refresh')

    $durationSelect.html($(`.durations-template[data-durationtype="${target}"]`).html())

    const $firstOption = $durationSelect.find('option').first()
    $firstOption.prop('disabled', false) // Otherwise it cannot be selected
    $durationSelect.selectpicker('refresh')
    $durationSelect.selectpicker('val', $firstOption.val()) // This must come after refresh because the totally new HTML will trigger sort of a reinit

    updateAvailableServices()
  })

  $('.subscriptiontype[data-target="regular"]').click()
})
